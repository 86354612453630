<template>
	<!-- 线下充值 -->
	<div class="fund-offline-recharge el-content">
		<a-tabs defaultActiveKey>
		    <a-tab-pane key="1" tab="申请列表">
				<a-table :pagination="false" row-key="id" :data-source="info.list" :columns="[
					{title:'充值用户',dataIndex:'account',slots:{customRender:'account'}},
					{title:'充值金额',dataIndex:'money'},
					{title:'提交时间',dataIndex:'create_time'},
					{title:'充值状态',dataIndex:'status',slots:{customRender:'status'}},
					{title:'操作',dataIndex:'action',slots:{customRender:'action'}},
				]" >
					<template #account="{record}">
						<div style="display: flex;align-items: center;" v-if="record.account">
							<a-avatar size="medium" :src="record.account.avatar_url"/>
							<span style="margin-left: 12px;">{{record.account.nickname}}</span>
						</div>
					</template>
					<template #status="{record}">
						<a-tag v-if="record.status == 1" color="#999">待处理</a-tag>
						<a-tag v-if="record.status == 2" color="#FF0066">已拒绝</a-tag>
						<a-tag v-if="record.status == 3" color="#00CC66">已处理</a-tag>
					</template>
					<template #action="{record}">
						<a-button type="link" @click="doDeal(record)">处理</a-button>
					</template>
				</a-table>
				<div class="pager">
					<a-pagination
						show-size-changer
						:default-current="info.page"
						:total="info.count"
						@showSizeChange="(p,e)=>{getOfflineRechargeList(info.page,e)}"
						@change="(e)=>{getOfflineRechargeList(e,info.limit)}"
					/>
				</div>
			</a-tab-pane>
			<a-tab-pane key="2" tab="充值设置">
				<com-power-form form-type="check" :formData="[
					{label:'开启线下充值',type:'switch',key:'is_open_offline_recharge',value:'',dataType:'string'},
					{label:'开户银行账号',type:'text',key:'offline_recharge_bank_account',value:'',dataType:'string',show:{key:'is_open_offline_recharge',value:'1'}},
					{label:'开户名称',type:'text',key:'offline_recharge_bank_name',value:'',dataType:'string',show:{key:'is_open_offline_recharge',value:'1'}},
					{label:'开户银行',type:'text',key:'offline_recharge_bank',value:'',dataType:'string',show:{key:'is_open_offline_recharge',value:'1'}},
					{label:'备注说明',type:'text',key:'offline_recharge_remark',value:'',dataType:'string',show:{key:'is_open_offline_recharge',value:'1'}},
				]"></com-power-form>
			</a-tab-pane>
		</a-tabs>
		<a-modal v-model:visible="show.deal" title="线下充值审核" @ok="dealOfflineRecharge" width="600px" @cancel="show.deal = false">
			<a-form :label-col="{ span: 4 }" :wrapper-col="{ span: 16 }">
				<a-form-item label="充值凭证" v-if="dealForm.images">
					<a-image 
						v-for="(item,index) in dealForm.images" 
						:key="index" 
						:src="item" 
						style="width: 100px;margin-left: 6px;"/>
				</a-form-item>
				<a-form-item label="充值金额">
					<span style="color: #f51e22;font-weight: bold;">{{dealForm.money}}元</span>
				</a-form-item>
				<a-form-item label="充值处理">
					<a-radio-group v-model:value="dealForm.status">
						<a-radio value="3">通过审核</a-radio>
						<a-radio value="2">拒绝审核</a-radio>
						<div style="font-size: 12px;color: #999;margin-top: 12px;">
							通过审核后，将对该用户的余额增加相应的充值金额；拒绝审核不做任何操作！
						</div>
					</a-radio-group>
				</a-form-item>
				<a-form-item label="拒绝理由" v-if="dealForm.status == 2">
					<a-input type="textarea" v-model:value="dealForm.msg"></a-input>
				</a-form-item>
			</a-form>
		</a-modal>
	</div>
</template>

<script>
import comPowerForm from '@/components/form/com-power-form.vue'
import userModel from '@/api/user.js'
import { reactive, toRefs } from 'vue'
export default{
	components:{
		comPowerForm
	},
	setup(){
		const _d = reactive({
			info:{
				list:[],
				page:1,
				limit:10,
				count:0
			},
			show:{ deal:false },
			//线下充值处理信息
			dealForm:null
		})
		getOfflineRechargeList(1,_d.info.limit)
		function getOfflineRechargeList(page,limit){
			userModel.getOfflineRecharge(page,limit,res=>_d.info = {limit,...res})
		}
		function doDeal(row){
			_d.dealForm = {
				id:row.id,
				images:row.images,
				money:row.money,
				msg:"",
				status:0,
			}
			_d.show.deal = true
		}
		function dealOfflineRecharge(){
			let data = {
				status:_d.dealForm.status,
				id:_d.dealForm.id,
				msg:_d.dealForm.msg,
			}
			userModel.handleOfflineRechage(data,()=>getOfflineRechargeList(_d.info.page,_d.info.limit))
		}

		return{
			...toRefs(_d),
			getOfflineRechargeList,
			doDeal,
			dealOfflineRecharge
		}
	}
}
</script>

<style lang="scss">
</style>
